import { lazy } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import InitializeApp from '@components/router/initialize-app';
import Guard from '@components/router/page-guard';
import Suspense from '@components/router/page-suspense';
import { warehouseRoute } from '@lib/routes-utils';
import { InventorySection } from '@models/inventory';

const AgentConfig = lazy(() => import('@pages/settings/agent-config'));
const Analytics = lazy(() => import('@pages/analytics'));
const AreaSettings = lazy(() => import('@pages/settings/areas'));
const BarcodeEnrolement = lazy(() => import('@pages/settings/barcode-enrollment'));
const Barcodes = lazy(() => import('@pages/settings/barcodes'));
const BinDetails = lazy(() => import('@pages/bin'));
const BinSettings = lazy(() => import('@pages/settings/bins'));
const BinSizeDetails = lazy(() => import('@pages/bin-size'));
const BinSizesSettings = lazy(() => import('@pages/settings/bin-sizes'));
const BusinessPartners = lazy(() => import('@pages/settings/business-partners'));
const CompanySettings = lazy(() => import('@pages/settings/companies'));
const ContractorValidationLog = lazy(() => import('@pages/settings/contractor-validation-log'));
const DatasetRunSummaries = lazy(() => import('@pages/slotting/dataset/runs'));
const Datasets = lazy(() => import('@pages/slotting/datasets'));
const Deliveries = lazy(() => import('@pages/deliveries'));
const DeveloperTools = lazy(() => import('@pages/settings/developer-tools'));
const DevIsolation = lazy(() => import('@pages/dev-isolation'));
const DoorSettings = lazy(() => import('@pages/settings/doors'));
const EntityHistory = lazy(() => import('@pages/settings/entity-history'));
const EquipmentModelSettings = lazy(() => import('@pages/settings/equipment-models'));
const EquipmentSettings = lazy(() => import('@pages/settings/equipment'));
const EquipmentTypeSettings = lazy(() => import('@pages/settings/equipment-types'));
const ErpIntegrationSettings = lazy(() => import('@pages/settings/erp-integration'));
const FulfillmentBlocksSettings = lazy(() => import('@pages/settings/fulfillment-blocks'));
const InboundDeliveries = lazy(() => import('@pages/deliveries/inbound-deliveries'));
const InboundFileProcessing = lazy(() => import('@pages/settings/inbound-file-processing'));
const IntegrationLog = lazy(() => import('@pages/integration-log'));
const IntegrationLogs = lazy(() => import('@pages/settings/integration-logs'));
const InventoryCounting = lazy(() => import('@pages/inventory/counting'));
const InventoryIntegration = lazy(() => import('@pages/settings/inventory-integration'));
const InventoryLayout = lazy(() => import('@pages/inventory/layout'));
const InventoryLedger = lazy(() => import('@pages/inventory/ledger'));
const InventoryReconciliation = lazy(() => import('@pages/settings/reconciliation'));
const InventoryReconciliationConflict = lazy(
  () => import('@pages/settings/reconciliation/conflict'),
);
const LandingPage = lazy(() => import('@pages/landing-page'));
const Layout = lazy(() => import('@/components/layout'));
const LicensePlate = lazy(() => import('@pages/license-plate'));
const LotDetails = lazy(() => import('@pages/lot'));
const LotSettings = lazy(() => import('@pages/settings/lots'));
const Map = lazy(() => import('@pages/map'));
const Operations = lazy(() => import('@pages/operations'));
const OrganizationSettings = lazy(() => import('@pages/settings/organization'));
const OutboundDeliveries = lazy(() => import('@pages/deliveries/outbound-fulfillments'));
const PartnerDetails = lazy(() => import('@pages/business-partner'));
const ProductDetails = lazy(() => import('@pages/product'));
const ProductSettings = lazy(() => import('@pages/settings/products'));
const RolesAndPermissions = lazy(() => import('@pages/settings/roles-and-permissions'));
const RulesetDetails = lazy(() => import('@pages/slotting/ruleset'));
const Rulesets = lazy(() => import('@pages/slotting/rulesets'));
const Settings = lazy(() => import('@pages/settings'));
const SlottingConfiguration = lazy(() => import('@pages/settings/slotting-configuration'));
const SlottingDashboard = lazy(() => import('@pages/slotting/dashboard'));
const SlottingRun = lazy(() => import('@pages/slotting/run'));
const SlottingRuns = lazy(() => import('@pages/slotting/runs'));
const StockStatusSettings = lazy(() => import('@pages/settings/stock-status'));
const SupportPage = lazy(() => import('@pages/support'));
const SupportProvider = lazy(() => import('@context/support'));
const SystemConnectionsSettings = lazy(() => import('@pages/settings/system-connections'));
const Task = lazy(() => import('@pages/task'));
const TaskGroupDetails = lazy(() => import('@pages/task-group'));
const Tasks = lazy(() => import('@pages/tasks'));
const TaskTypeBinStatus = lazy(() => import('@pages/settings/task-type-bin-status'));
const TaskTypeStockStatus = lazy(() => import('@pages/settings/task-type-stock-status'));
const TeamSettings = lazy(() => import('@pages/settings/teams'));
const UomGlossary = lazy(() => import('@pages/settings/uom-glossary'));
const UserAdminSettings = lazy(() => import('@pages/settings/user-admin'));
const UserGroups = lazy(() => import('@pages/settings/user-groups'));
const WarehouseMappingSettings = lazy(() => import('@pages/settings/warehouse-mapping'));
const WarehousePaths = lazy(() => import('@pages/settings/warehouse-paths'));
const WarehouseProviders = lazy(() => import('@context/warehouse-providers'));
const WarehouseRoleTypes = lazy(() => import('@pages/settings/warehouse-role-types'));
const WarehouseSelect = lazy(() => import('@pages/warehouse-select'));
const WarehouseSettings = lazy(() => import('@pages/settings/warehouses'));
const ZoneSettings = lazy(() => import('@pages/settings/zone'));
const ZonesSettings = lazy(() => import('@pages/settings/zones'));

export const PostAuthRouter = () => {
  return (
    <Routes>
      {process.env.REACT_APP_ENV === 'development' && (
        <Route path="/isolation" element={<DevIsolation />} />
      )}
      <Route
        path="/"
        element={
          <Suspense>
            <WarehouseSelect />
          </Suspense>
        }
      />
      <Route
        path="/w/:warehouseShortHash"
        element={
          <Suspense>
            <WarehouseProviders>
              <InitializeApp>
                <Layout />
              </InitializeApp>
            </WarehouseProviders>
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense>
              <LandingPage />
            </Suspense>
          }
        />
        <Route
          path="operations"
          element={
            <Suspense>
              <Operations />
            </Suspense>
          }
        />
        <Route path="analytics" element={<Guard guard="page.analytics" />}>
          <Route
            path="dashboard/:dashboardId"
            element={
              <Suspense>
                <Analytics />
              </Suspense>
            }
          />
          {/* <Route path="search" element={<AnalyticsSearch />} /> */}
        </Route>
        <Route
          path="bin/:id"
          element={
            <Guard guard="page.bin">
              <Suspense>
                <BinDetails />
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="bin-size/:id"
          element={
            <Guard guard="page.bin-size">
              <Suspense>
                <BinSizeDetails />
              </Suspense>
            </Guard>
          }
        />
        <Route path="deliveries" element={<Guard guard="page.deliveries" />}>
          <Route
            index
            element={
              <Suspense>
                <Deliveries />
              </Suspense>
            }
          />
          <Route
            path={`inbound/:deliveryId`}
            element={
              <Suspense>
                <InboundDeliveries />
              </Suspense>
            }
          />
          <Route
            path={`outbound/:deliveryId`}
            element={
              <Suspense>
                <OutboundDeliveries />
              </Suspense>
            }
          />
        </Route>
        <Route
          path={`integration-log/:id`}
          element={
            <Suspense>
              <Guard guard="page.integration-log-details">
                <IntegrationLog />
              </Guard>
            </Suspense>
          }
        />
        <Route
          path="inventory"
          element={
            <Suspense>
              <InventoryLayout />
            </Suspense>
          }
        >
          <Route
            path=":level"
            element={
              <Guard guard="page.inventory">
                <Suspense>
                  <InventoryLedger />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path={`${InventorySection.Counting}`}
            element={
              <Guard guard="page.inventory">
                <Suspense>
                  <InventoryCounting />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path={`${InventorySection.Counting}/tasks`}
            element={
              <Guard guard="page.inventory">
                <Suspense>
                  <InventoryCounting />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path={`${InventorySection.Counting}/review`}
            element={
              <Guard guard="page.inventory">
                <Suspense>
                  <InventoryCounting />
                </Suspense>
              </Guard>
            }
          />
        </Route>

        <Route
          path="license-plate/:id"
          element={
            <Guard guard="page.license-plate">
              <Suspense>
                <LicensePlate />
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="lot/:id"
          element={
            <Guard guard="page.lot">
              <Suspense>
                <LotDetails />
              </Suspense>
            </Guard>
          }
        />
        <Route path="slotting">
          <Route
            index
            element={
              <Guard guard="page.slotting.dashboard">
                <Suspense>
                  <SlottingDashboard />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="runs"
            element={
              <Guard guard="page.slotting.runs">
                <Suspense>
                  <SlottingRuns />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="run/:id"
            element={
              <Guard guard="page.slotting.runs">
                <Suspense>
                  <SlottingRun />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="datasets"
            element={
              <Guard guard="page.slotting.datasets">
                <Suspense>
                  <Datasets />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="dataset/:id/runs"
            element={
              <Guard guard="page.slotting.dataset.run-summaries">
                <Suspense>
                  <DatasetRunSummaries />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path={'rulesets'}
            element={
              <Guard guard="page.slotting.rulesets">
                <Suspense>
                  <Rulesets />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path={'ruleset/:id'}
            element={
              <Guard guard="page.slotting.ruleset.details">
                <Suspense>
                  <RulesetDetails />
                </Suspense>
              </Guard>
            }
          />
        </Route>
        <Route
          path="map"
          element={
            <Guard guard="page.map">
              <Suspense>
                <Map />
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="business-partner/:id"
          element={
            <Guard guard="page.business-partner">
              <Suspense>
                <PartnerDetails />
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="product/:id"
          element={
            <Guard guard="page.product">
              <Suspense>
                <ProductDetails />
              </Suspense>
            </Guard>
          }
        />
        <Route path="settings">
          <Route
            index
            element={
              <Suspense>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path="areas"
            element={
              <Guard guard="page.settings.areas">
                <Suspense>
                  <AreaSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="bins"
            element={
              <Guard guard="page.settings.bins">
                <Suspense>
                  <BinSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="bin-sizes"
            element={
              <Guard guard="page.settings.bin-sizes">
                <Suspense>
                  <BinSizesSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="business-partners"
            element={
              <Guard guard="page.settings.business-partners">
                <Suspense>
                  <BusinessPartners />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="companies"
            element={
              <Guard guard="page.settings.companies">
                <Suspense>
                  <CompanySettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="contractor-validation-log"
            element={
              <Guard guard="page.settings.contractor-validation-log">
                <Suspense>
                  <ContractorValidationLog />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="doors"
            element={
              <Guard guard="page.settings.doors">
                <Suspense>
                  <DoorSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="fulfillment-blocks"
            element={
              <Guard guard="page.settings.fulfillment-blocks">
                <Suspense>
                  <FulfillmentBlocksSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="entity-history"
            element={
              <Guard guard="page.settings.entity-history">
                <Suspense>
                  <EntityHistory />
                </Suspense>
              </Guard>
            }
          />
          <Route path="equipment" element={<Guard guard="page.settings.equipment" />}>
            <Route index element={<EquipmentSettings />} />
            <Route
              path="models"
              element={
                <Guard guard="page.settings.equipment.models">
                  <Suspense>
                    <EquipmentModelSettings />
                  </Suspense>
                </Guard>
              }
            />
            <Route
              path="types"
              element={
                <Guard guard="page.settings.equipment.types">
                  <Suspense>
                    <EquipmentTypeSettings />
                  </Suspense>
                </Guard>
              }
            />
          </Route>
          <Route
            path="erp-integration/:systemConnectionId/warehouse/:warehouseId"
            element={
              <Guard guard="page.settings.erp-integration">
                <Suspense>
                  <ErpIntegrationSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="inbound-file-processing"
            element={
              <Guard guard="page.settings.inbound-file-processing">
                <Suspense>
                  <InboundFileProcessing />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="integration-logs"
            element={
              <Guard guard="page.settings.integration-logs">
                <Suspense>
                  <IntegrationLogs />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="inventory-integration"
            element={
              <Guard guard="page.settings.inventory-integration">
                <Suspense>
                  <InventoryIntegration />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="lots"
            element={
              <Guard guard="page.settings.lots">
                <Suspense>
                  <LotSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="organization"
            element={
              <Guard guard="page.settings.organization">
                <Suspense>
                  <OrganizationSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="products"
            element={
              <Guard guard="page.settings.products">
                <Suspense>
                  <ProductSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="reconciliation/:view?"
            element={
              <Guard guard="page.settings.inventory-reconciliation">
                <Suspense>
                  <InventoryReconciliation />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="reconciliation/conflict/:id"
            element={
              <Guard guard="page.settings.inventory-reconciliation">
                <Suspense>
                  <InventoryReconciliationConflict />
                </Suspense>
              </Guard>
            }
          />
          <Route path="roles-and-permissions">
            <Route
              index
              element={
                <Guard guard="page.settings.roles-and-permissions">
                  <Suspense>
                    <RolesAndPermissions />
                  </Suspense>
                </Guard>
              }
            />
            <Route
              path=":id"
              element={
                <Guard guard="page.settings.roles-and-permissions">
                  <Suspense>
                    <RolesAndPermissions />
                  </Suspense>
                </Guard>
              }
            />
          </Route>
          <Route
            path="user-assignment-groups"
            element={
              <Guard guard="page.settings.user-assignment-groups">
                <Suspense>
                  <UserGroups />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="stock-status"
            element={
              <Guard guard="page.settings.stock-status">
                <Suspense>
                  <StockStatusSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="system-connections"
            element={
              <Guard guard="page.settings.system-connections">
                <Suspense>
                  <SystemConnectionsSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="task-type-bin-status"
            element={
              <Guard guard="page.settings.task-type-bin-status">
                <Suspense>
                  <TaskTypeBinStatus />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="task-type-stock-status"
            element={
              <Guard guard="page.settings.task-type-stock-status">
                <Suspense>
                  <TaskTypeStockStatus />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="teams"
            element={
              <Guard guard="page.settings.teams">
                <Suspense>
                  <TeamSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="user-admin"
            element={
              <Guard guard="page.settings.user-admin">
                <Suspense>
                  <UserAdminSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="warehouses"
            element={
              <Guard guard="page.settings.warehouses">
                <Suspense>
                  <WarehouseSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="warehouse-mapping"
            element={
              <Guard guard="page.settings.warehouse-mapping">
                <Suspense>
                  <WarehouseMappingSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="warehouse-role-types"
            element={
              <Guard guard="page.settings.warehouse-role-types">
                <Suspense>
                  <WarehouseRoleTypes />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="warehouse-paths"
            element={
              <Guard guard="page.settings.warehouse-paths">
                <Suspense>
                  <WarehousePaths />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="barcodes"
            element={
              <Guard guard="page.settings.barcodes">
                <Suspense>
                  <Barcodes />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="barcode-enrollment"
            element={
              <Guard guard="page.settings.barcode-enrollment">
                <Suspense>
                  <BarcodeEnrolement />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="agent-config"
            element={
              <Guard guard="page.settings.agent-config">
                <Suspense>
                  <AgentConfig />
                </Suspense>
              </Guard>
            }
          />
          <Route path="developer-tools" element={<DeveloperTools />} />
          <Route
            path="uom-glossary"
            element={
              <Guard guard="page.settings.uom-glossary">
                <Suspense>
                  <UomGlossary />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="zones"
            element={
              <Guard guard="page.settings.zones">
                <Suspense>
                  <ZonesSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="zone/:zoneId"
            element={
              <Guard guard="page.settings.zone">
                <Suspense>
                  <ZoneSettings />
                </Suspense>
              </Guard>
            }
          />
          <Route
            path="slotting-configuration"
            element={
              <Guard guard="page.settings.slotting-configuration">
                <Suspense>
                  <SlottingConfiguration />
                </Suspense>
              </Guard>
            }
          />
        </Route>
        <Route
          path="support"
          element={
            <Guard guard="page.support">
              <Suspense>
                <SupportProvider>
                  <SupportPage />
                </SupportProvider>
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="tasks"
          element={
            <Guard guard="page.tasks">
              <Suspense>
                <Tasks />
              </Suspense>
            </Guard>
          }
        />
        <Route
          path="task/:id"
          element={
            <Guard guard="page.task">
              <Suspense>
                <Task />
              </Suspense>
            </Guard>
          }
        />
        <Route path="task-group/:id" element={<TaskGroupDetails />} />
      </Route>

      {/* 404 Handling - For now navigate to / (unauthorized 404s will then be navigated to /login) */}
      <Route path="*" element={<Navigate replace={true} to={warehouseRoute('/')} />} />
    </Routes>
  );
};
