import React, { useState, useContext, createContext, useEffect, useMemo, useCallback } from 'react';

import usePrevious from '@hooks/usePrevious';

interface IShortcutContext {
  addCustomShortcut: (shortcut: ICustomShortcut) => void;
  removeCustomShortcut: (shortcutId: string) => void;
}
const ShortcutContext = createContext<IShortcutContext>(null);

interface ICustomShortcut {
  id: string;
  keyCode: string;
  action: () => void;
}

const ShortcutProvider = ({ children }) => {
  const [customShortcuts, setCustomShortcuts] = useState<ICustomShortcut[]>([]);

  const addCustomShortcut = (shortcut: ICustomShortcut) => {
    setCustomShortcuts([...customShortcuts, shortcut]);
  };

  const removeCustomShortcut = (shortcutId: string) => {
    const currentCustomShortcuts = [...customShortcuts];
    setCustomShortcuts(currentCustomShortcuts.filter((shortcut) => shortcut.id !== shortcutId));
  };

  // At this point, we don't want multiple actions per keyCode so we create a boundShortcuts object that bind one action to one keyCode.
  // In the future when we have built-in shortcuts we can use this approach to have custom shortcuts override built-in shortcuts.
  type TBoundShortcuts = Record<string, () => void>;
  const boundShortcuts: TBoundShortcuts = useMemo(() => {
    const shortcuts: TBoundShortcuts = {};
    customShortcuts.forEach((shortcut) => {
      shortcuts[shortcut.keyCode] = shortcut.action;
    });
    return shortcuts;
  }, [customShortcuts]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        if (typeof boundShortcuts[e.code] === 'function') boundShortcuts[e.code]();
      }
    },
    [boundShortcuts],
  );

  // prevHandleKeyDown gives us reference to the last memoized handleKeyDown function, so we can remove the eventListener for the old set of boundShortcuts.
  const prevHandleKeyDown = usePrevious(handleKeyDown);
  useEffect(() => {
    document.removeEventListener('keydown', prevHandleKeyDown);
    document.addEventListener('keydown', handleKeyDown);
  }, [prevHandleKeyDown]);

  return (
    <ShortcutContext.Provider value={{ addCustomShortcut, removeCustomShortcut }}>
      {children}
    </ShortcutContext.Provider>
  );
};

export default ShortcutProvider;

export const useShortcuts = () => {
  const ctx = useContext(ShortcutContext);

  if (ctx === null) {
    throw new Error('useShortcuts must be used within ShortcutProvider');
  }

  // Example Usage (As this has been removed from the login form)
  // const shortcutId = 'emailPassShortcut';
  // removeCustomShortcut(shortcutId);
  // addCustomShortcut({
  //   id: shortcutId,
  //   keyCode: 'KeyE',
  //   action: () => {
  //     setLoginMethod(
  //       loginMethod === LoginMethods.POPUP ? LoginMethods.EMAIL_PASS : LoginMethods.POPUP,
  //     );
  //   },
  // });

  return ctx;
};
