import { AxiosError } from 'axios';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getWebConfig } from '@/apis/axios';
import AppLoadingKeys from '@context/app-loading/AppLoadingKeys';
import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';
import DEFAULT_FEATURE_FLAG_TOGGLES from '@context/feature-flags/feature-flag-defaults';
import {
  IApplicationFeatureFlagToggles,
  IFeatureFlagToggles,
  TWarehouseTypeFeatureFlags,
} from '@context/feature-flags/feature-flag-types';
import { useSnackbar } from '@context/snackbar';
import _stubbedFeatureFlags from '@models/_stubbed-feature-flags';
import MaintenanceMode from '@pages/maintenance-mode';
interface IFeatureFlagsContext {
  applicationFeatureFlags: IApplicationFeatureFlagToggles;
  warehouseTypeFeatureFlags: TWarehouseTypeFeatureFlags;
}
const FeatureFlagsContext = createContext<IFeatureFlagsContext>(null);

const FeatureFlagsProvider = ({ children }) => {
  const { t } = useTranslation('pages');
  const { addAppLoadingRequest, removeAppLoadingRequest } = useAppLoadingToggle();
  const { showMessage } = useSnackbar();

  const hasInitializedFlags = useRef(false);
  const [isLoadingFeatureFlags, setIsLoadingFeatureFlags] = useState(true);
  const [_featureFlags, setFeatureFlags] = useState<IFeatureFlagToggles>(null);
  useEffect(() => {
    addAppLoadingRequest(AppLoadingKeys.FeatureFlags, t('appLoading.featureFlags'));
    setIsLoadingFeatureFlags(true);

    if (process.env.REACT_APP_STUB_FEATURE_FLAGS !== 'true') {
      getWebConfig()
        .then(({ data: featureFlags }) => {
          setFeatureFlags(Object.assign({}, DEFAULT_FEATURE_FLAG_TOGGLES, featureFlags));
        })
        .catch((error: AxiosError) => {
          console.error(`Feature Flags failed to load: ${error?.message}`);
          showMessage({ type: 'error', message: 'Error retrieving feature flags.' });
          setFeatureFlags(DEFAULT_FEATURE_FLAG_TOGGLES);
        });
    } else {
      setFeatureFlags(Object.assign({}, DEFAULT_FEATURE_FLAG_TOGGLES, _stubbedFeatureFlags));
      removeAppLoadingRequest(AppLoadingKeys.FeatureFlags);
    }
  }, [_stubbedFeatureFlags]);

  const [applicationFeatureFlags, warehouseTypeFeatureFlags] = useMemo<
    [IApplicationFeatureFlagToggles, TWarehouseTypeFeatureFlags]
  >(() => {
    let _applicationFlags = _featureFlags
      ? Object.assign({}, _featureFlags)
      : DEFAULT_FEATURE_FLAG_TOGGLES;
    let _warehouseTypeFlags = _featureFlags
      ? Object.assign({}, _featureFlags.warehouseTypes)
      : DEFAULT_FEATURE_FLAG_TOGGLES.warehouseTypes;

    delete _applicationFlags.warehouseTypes;

    if (_featureFlags !== null) {
      setIsLoadingFeatureFlags(false);
    }

    return [_applicationFlags, _warehouseTypeFlags];
  }, [_featureFlags]);

  useEffect(() => {
    if (!isLoadingFeatureFlags) {
      if (!hasInitializedFlags.current) {
        hasInitializedFlags.current = true;
      }
      removeAppLoadingRequest(AppLoadingKeys.FeatureFlags);
    }
  }, [isLoadingFeatureFlags]);

  if (isLoadingFeatureFlags) return null;
  return (
    <FeatureFlagsContext.Provider value={{ applicationFeatureFlags, warehouseTypeFeatureFlags }}>
      {applicationFeatureFlags.maintenanceMode.enabled ? <MaintenanceMode /> : children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;

export const useFeatureFlags = () => {
  const ctx = useContext(FeatureFlagsContext);
  if (ctx === null) {
    throw new Error('useFeatureFlags must be used within FeatureFlagsProvider');
  }
  return ctx;
};
