import { gql } from '@apollo/client';

import { GetSelectedWarehouseQuery } from '@/graphql/defs/context/__generated__/warehouse-utils.generated';

gql`
  fragment SelectedWarehouse on Warehouse {
    id
    code
    name
    type
    companyId
    contactInfo {
      id
      email
      phone
      fax
      street1
      street2
      city
      state
      zip
      country
    }
    displayPreference {
      id
      currency
      dateTimeFormat
      language
      numberFormat
      timezone
    }
    sapMappings {
      warehouseId
      plant
      storageLocations
      shippingPoints
    }
    systemConnection {
      id
      code
      systemType
      baseUrl
      timezone
      stockStatusSetting
    }
  }
`;

gql`
  query GetSelectedWarehouse($id: ID!) {
    warehouse(id: $id) {
      ...SelectedWarehouse
    }
  }
`;

export const GetSelectedWarehouse_defaultData: GetSelectedWarehouseQuery = {
  __typename: 'Query',
  warehouse: undefined,
};
