import { Link as MUILink, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

export type StyledLinkVariants =
  | 'dataGrid'
  | 'footer'
  | 'justifiedColumnBold'
  | 'noPreHoverStyle'
  | 'disabled'
  | 'inlineLink'
  | 'inventoryConflict';
interface IStyledLink {
  styledVariant?: StyledLinkVariants;
}

const styledVariants = (theme: Theme): Record<StyledLinkVariants, Record<any, any>> => ({
  dataGrid: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  disabled: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    pointerEvents: 'none',
  },
  footer: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.slateGrey.main,
    },
  },
  inlineLink: {
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  justifiedColumnBold: {
    color: 'inherit',
    fontWeight: 700,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  noPreHoverStyle: {
    color: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  inventoryConflict: {
    color: theme.palette.error.main,
    fontWeight: 600,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
});

const Link = styled(MUILink, {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledLink>(({ theme, styledVariant }) => styledVariants(theme)[styledVariant] || {});

export const ExternalLink = styled('a', {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledLink>(({ theme, styledVariant }) => styledVariants(theme)[styledVariant] || {});

export default Link;
